import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import { isDesktop } from "react-device-detect";
import Swal from "sweetalert2";

// Components
import PageHeader from "../../components/PageHeader/PageHeader";
import PageContent from "../../components/PageContent/PageContent";
import PageFooter from "../../components/PageFooter/PageFooter";
import BottomNav from "../../components/PageHeader/MobileBottomNav";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import routes from "../../routes";
import RightFixedButton from "../../components/RightFixedButton/RightFixedButton";
// import { defaultSeo } from "../../components/SeoMeta/SeoMeta";
import CountrySetting from "../Setting/CountrySetting";
// import DownloadAppBanner from "../../components/DownloadAppBanner/DownloadAppBanner";
import { Redirect } from "react-router-dom";
import domain from "../../common/data/domain.json";

let dynamicCountry = "malaysia";
if (domain.id.key.includes(window.location.host)) {
  dynamicCountry = "indonesia";
}

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceOs: "",
      currentEvent: "",
    };
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);

    window.onpopstate = (e) => {
      let body = document.querySelector("body");
      let backdrop = document.querySelector(".modal-backdrop");
      Swal.close();
      body.classList.remove("modal-open");
      if (backdrop) {
        backdrop.parentNode.removeChild(backdrop);
        body.style.overflow = null;
        body.style.paddingRight = null;
      } else return false;
    };

    this.mobileOsDetect();
  }

  componentWillMount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location &&
      this.props.location !== undefined &&
      this.props.location.pathname !== undefined &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      let firstPathName = window.location.pathname.split("/")[1];
      let ignoreList = ["slot", "games", "p2p", "lottery"];
      // if (!ignoreList.includes(firstPathName)) {
      //   window.scrollTo(0, 0);
      // }
        window.scrollTo(0, 0);      
    }
  }

  updatePredicate() {
    document.documentElement.style.setProperty(
      "--app-height",
      `${window.innerHeight}px`
    );
  }

  mobileOsDetect = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      this.setState({
        deviceOs: "Windows Phone",
      });
    }
    if (/android/i.test(userAgent)) {
      this.setState({
        deviceOs: "android",
      });
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      this.setState({
        deviceOs: "ios",
      });
    }
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;

    const christmas = this.imageImport(
      require.context(
        "../../assets/images/home/christmas",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    // const chinese = this.imageImport(
    //   require.context(
    //     "../../assets/images/home/chinese",
    //     false,
    //     /\.(png|jpe?g|svg|webp)$/
    //   )
    // );

    const { pathname } = this.props.location;
    const registerPath = window.location.pathname === "/register";
    const registerSocialMediaPath =
      window.location.pathname === "/registerSocialMedia";
    const forgetPwdPath = window.location.pathname === "/forget-password";
    const loginPath = window.location.pathname === "/login";
    const maintenance = window.location.pathname === "/maintenance";
    const errorPage = window.location.pathname === "/404";
    const restriction = window.location.pathname === "/restriction";
    let verifyPwdPathSplit = window.location.pathname.split("/");

    const verifyPwdPath = verifyPwdPathSplit[1] === "verify-forgot-password";

    const bottomNav =
      window.location.pathname === "/slot" ||
      window.location.pathname === `/online-sports-games-${dynamicCountry}` ||
      window.location.pathname === `/online-live-games-${dynamicCountry}` ||
      window.location.pathname === "/games" ||
      window.location.pathname === "/p2p" ||
      window.location.pathname === "/lottery" ||
      window.location.pathname === "/me" ||
      window.location.pathname === "/promos" ||
      window.location.pathname === "/about-us" ||
      window.location.pathname === "/sponsor" ||
      window.location.pathname === "/bet-and-earn" ||
      window.location.pathname === "/check-in" ||
      window.location.pathname === "/fifa-world-cup" ||
      window.location.pathname === "/lucky-spin" ||
      window.location.pathname === "/fifa-qna" ||
      window.location.pathname === "/live-streaming" ||
      window.location.pathname === "/download-app" ||
      window.location.pathname === "/leaderboard" ||
      window.location.pathname === "/bonus" ||
      loginPath ||
      registerPath ||
      forgetPwdPath
    ;


    const notFirstTime = localStorage.getItem("notFirstTime");
    if (!notFirstTime && !isDesktop) {
      let referralCode = new URLSearchParams(document.location.search).get(
        "refCode"
      );
      if (referralCode !== null) {
        sessionStorage.setItem("referralCode", referralCode);
        sessionStorage.setItem("updateView", 1);
      }
      localStorage.setItem("notFirstTime", true);
      // return <Redirect to="/download-app" />;
      return <Redirect to={`/online-live-games-${dynamicCountry}`} />
    }

    return (
      <Auxiliary>
        {isDesktop ? (
          <Helmet>
            <html className="desktop-view" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />
          </Helmet>
        ) : (
          <Helmet>
            <html className="mobile-view" />
            <meta
              name="viewport"
              content="user-scalable=no,viewport-fit=cover"
            />
          </Helmet>
        )}

        <Helmet>
          {routes.map((route, index) => {
            // if (route.path === pathname && route.path !== "/")
            if (route.path === pathname) {
              let dynamicTitleCountry = "Malaysia";
              if (domain.id.key.includes(window.location.host)) {
                dynamicTitleCountry = "Indonesia";
              }
              let dynamicTitle = t(`routes:title.${route.slug}`, { country: dynamicTitleCountry });
              return (
                <title key={index}>
                  R77 | {dynamicTitle}
                </title>
              );
            } else {
              return null;
            }
          })}
        </Helmet>

        {<CountrySetting />}

        {
          // registerPath ||
          // forgetPwdPath ||
          // loginPath ||
          registerSocialMediaPath ||
          verifyPwdPath
          // maintenance ||
          // errorPage ||
          // restriction
           ? (
          <div className="master-container">
            <div className="master-content">
              <PageContent />
            </div>
          </div>
        ) : isDesktop ? (
          <>
            {this.state.currentEvent === "christmas" ? (
              <img
                className="master-bg"
                src={christmas["master-bg.jpg"]}
                alt="background"
              />
            ) : (
              <></>
            )}
            <div className="master-container">
              <PageHeader />
              <div className="master-content">
                <PageContent />
                <RightFixedButton />
              </div>
              <PageFooter />
            </div>
          </>
        ) :          (
          <div className="master-container">
            <div className="master-content">
              {/* {window.location.pathname !== "/" && <DownloadAppBanner os={this.state.deviceOs} />} */}
              <PageContent />
              {!localStorage.getItem("browseByApp") && <RightFixedButton />}
            </div>
            {bottomNav && <BottomNav />}
          </div>         
          )}
      </Auxiliary>
    );
  }
}

export default withNamespaces("translation")(Layout);
